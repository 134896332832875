import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { MapAdapterRoad, MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { RoadwayDetailSegmentSelectListener } from "../../containers/RoadwayDetailSegmentSelectListener"
import { RoadwaySegmentSelectListener } from "../../containers/RoadwaySegmentSelectListener"

export const RoadwayDesktopRoutes = () => {

    const roadwayRoutes = useMemo(() => ([
        {
            path: "/",
            element: <>
                <MapAdapterBoundingBox />
                <RoadwaySegmentSelectListener />
            </>,
        },
        {
            path: "/:road/*",
            element: <>
                <RoadwayDetailSegmentSelectListener />
                <MapAdapterRoad />
            </>,
        },
    ]), [])

    return useRoutes(roadwayRoutes)
}