import { useMemo } from "react"

import { useTheme } from "@emotion/react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./TabulatedLayout.styled"

export function TabulatedLayout(props) {

    const {
        className,
        handleChange,
        tabsConfig,
        value,
    } = props

    const theme = useTheme()

    const tabsAttributes = useMemo(() => {
        return tabsConfig.map((config, index) => {
            return {
                active: true,
                icon: config.icon,
                iconPosition: "start",
                id: config.id,
                label: config.label,
                value: index,
            }
        })
    }, [tabsConfig])

    const tabsPanels = useMemo(() => {
        return tabsConfig.map((config, index) => {
            const children = config?.element

            return (<Styled.TabPanel
                currentTabIndex={value}
                index={index}
                key={`tab-panel-${index}`}
                margin={!config.fullScreen}
            >
                {children}
            </Styled.TabPanel>
            )
        })
    }, [tabsConfig, value])

    return (
        <Styled.Container className={className}>
            <Styled.Tabs
                handleChangeTab={handleChange}
                tabsAttributes={tabsAttributes}
                TabIndicatorProps={{ style: { background: theme.palette["cta-bg/cta-bg-primary"].main } }}
                value={value}
                variant="fullWidth"
            />
            {tabsPanels}
        </Styled.Container>
    )
}

TabulatedLayout.propTypes = {
    className: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    tabsConfig: PropTypes.arrayOf(PropTypes.shape({
        element: PropTypes.element,
        icon: PropTypes.element,
        id: PropTypes.string,
        label: PropTypes.string,
    }).isRequired),
    value: PropTypes.number.isRequired,
}
