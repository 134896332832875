import { createContext } from "react"

export const initialStateContext = {
    accessToken: null,
    refreshToken: null,
}
const initialDispatchContext = {}

export const AuthenticationStateContext = createContext(initialStateContext)
export const AuthenticationDispatchContext = createContext(initialDispatchContext)
