import _ from "lodash"

export function getClusterCountLayerConfig(config) {
    const baseConfig = {
        type: "symbol",
        filter: ["has", "point_count"],
        layout: {
            "text-field": "{point_count_abbreviated}",
        },
    }

    return _.merge(baseConfig, config)
}
