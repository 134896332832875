import { useEffect } from "react"

import { useMapDispatchContext } from "@l2r-front/l2r-map"

import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext"

export function RoadworksSegmentSelectListener() {

    const { setSelectedSegment } = useRoadworksDispatchContext()
    const { storeMapBoundingBox } = useMapDispatchContext()

    useEffect(() => {
        setSelectedSegment(null)
    }, [setSelectedSegment, storeMapBoundingBox])
}