import { useMemo } from "react"

import * as turf from "@turf/turf"

import { useGeoServerFeaturesList, useReverseGeocoding } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { Typography } from "@l2r-front/l2r-ui"

import { networkGradingsQueryKeys } from "../../../modules/roadway/hooks/queries/networkGradings/queryKeys"
import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./RoadDetailCity.styled"

export const RoadDetailCity = (props) => {

    const { road } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const { selectedNetwork } = useNetworksStateContext()

    const queryParams = {
        layer: selectedNetwork.referential.layerSection,
        linear_location_road: road,
    }
    const { data: roadSegments, isLoading, isError } = useGeoServerFeaturesList(
        networkGradingsQueryKeys.list(queryParams),
        queryParams,
        {
            enabled: !!road && !!selectedNetwork.referential.layerSection,
        })

    const segmentCenter = useMemo(() => {
        if (!roadSegments?.length) {
            return null
        }

        const segment = roadSegments[0]
        if (segment?.geometry) {
            return turf.center(segment.geometry).geometry.coordinates
        } else {
            return null
        }
    }, [roadSegments])

    const { data: geocodingData, isLoading: isLoadingGeocoding, isError: isErrorGeocoding } = useReverseGeocoding(
        segmentCenter ? segmentCenter[1] : null,
        segmentCenter ? segmentCenter[0] : null,
        13,
        {
            enabled: !!segmentCenter,
        })

    const segmentCity = useMemo(() => {
        if (!geocodingData) {
            return null
        }
        return geocodingData?.address?.city ?? geocodingData?.address?.town ?? geocodingData?.address?.village
    }, [geocodingData])

    if (isError || isErrorGeocoding) {
        return <Styled.ErrorText variant="Regular">{t(I18N_NAMESPACE, "containers.segmentDetailCity.error")}</Styled.ErrorText>
    }

    if (isLoading || isLoadingGeocoding) {
        return <Styled.Skeleton />
    }

    return (
        <Typography id="road-detail-city" variant="Regular">{segmentCity}</Typography>
    )
}