import { styled } from "@l2r-front/l2r-ui"

export const List = styled("ul")(() => ({
    paddingInlineStart: 0,
    margin: 0,
}))

export const ListElement = styled("div")({
    alignItems: "center",
    display: "flex",
})
