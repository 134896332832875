import { useMemo, useCallback } from "react"

import { useLocation } from "react-router-dom"

import {
    CLUSTER_LAYER_SIZE,
    POINT_LAYER_SIZE,
    MAX_ZOOM_LEVEL_CLUSTERED,
    INCIDENTS_LAYER,
    INCIDENTS_LAYER_CLUSTER,
    INCIDENTS_LAYER_CLUSTER_COUNT,
    INCIDENTS_SOURCE,
    Layer,
    PLACE_ICON_ID,
    Source,
    useMapDispatchContext,
    getPointSymbolLayerConfig,
    getClusterLayerConfig,
    getClusterCountLayerConfig,
    useOrderingLayers,
} from "@l2r-front/l2r-map"
import { useTheme } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams, uuidRegex } from "@l2r-front/l2r-utils"

import { useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import { IncidentsSource } from "../IncidentsSource"

export function IncidentsLayers(props) {

    const {
        ...sourceProps
    } = props

    const { selectedIncidentUuid } = useSvraiStateContext()
    const { storeMapBoundingBox } = useMapDispatchContext()
    const navigate = useNavigateWithSearchParams()
    const theme = useTheme()
    const location = useLocation()

    const layerColor = useMemo(() => {
        return selectedIncidentUuid ? theme.palette.map.disabled : theme.palette.map.primary
    }, [theme, selectedIncidentUuid])

    const incidentsLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }

        return getPointSymbolLayerConfig({
            layout: {
                "icon-image": PLACE_ICON_ID,
                "icon-size": POINT_LAYER_SIZE[0],
                "icon-anchor": "bottom",
            },
            paint: {
                "icon-color": layerColor,
            },
        })
    }, [theme, layerColor])

    const incidentsClusterLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }
        return getClusterLayerConfig({
            paint: {
                "circle-color": layerColor,
                "circle-radius": CLUSTER_LAYER_SIZE,
            },
        })
    }, [theme, layerColor])

    const incidentsClusterCountLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }
        return getClusterCountLayerConfig({
            layout: {
                "text-size": theme.typography.fontSize,
            },
            paint: {
                "text-color": theme.palette.map.text,
            },
        })
    }, [theme])

    const handleIncidentsClusterLayerClick = useCallback((event) => {
        const map = event.target

        const features = map.queryRenderedFeatures(event.point, {
            layers: ["svraiIncidentsLayerCluster"],
        })

        const feature = features[0]
        const geometry = feature.geometry
        const clusterId = feature?.properties?.["cluster_id"]

        const source = map.getSource("svraiIncidentsSource")
        source.getClusterExpansionZoom(clusterId, (error, zoom) => {
            if (error) {
                return
            }
            map.easeTo({
                center: geometry.coordinates,
                zoom: zoom + 1,
            })
        })
    }, [])

    const handleIncidentsLayerClick = useCallback(async (event) => {
        const feature = event?.features?.[0]

        if (!feature) {
            return
        }

        const frame = await requestAnimationFrame(() => {
            if (selectedIncidentUuid) {
                const newLocation = location.pathname.replace(uuidRegex, `${feature.properties.uuid}`)
                navigate(newLocation)
            } else {
                storeMapBoundingBox()
                navigate(feature.properties.uuid)
            }
            return cancelAnimationFrame(frame)
        })
    }, [navigate, selectedIncidentUuid, location, storeMapBoundingBox])

    useOrderingLayers([INCIDENTS_LAYER, INCIDENTS_LAYER_CLUSTER, INCIDENTS_LAYER_CLUSTER_COUNT])

    return <IncidentsSource
        id={INCIDENTS_SOURCE}
        cluster={true}
        clusterMaxZoom={MAX_ZOOM_LEVEL_CLUSTERED}
        {...sourceProps}
    >
        <Layer
            {...incidentsLayerConfig}
            id={INCIDENTS_LAYER}
            onClick={handleIncidentsLayerClick}
        />
        <Layer
            {...incidentsClusterLayerConfig}
            id={INCIDENTS_LAYER_CLUSTER}
            onClick={handleIncidentsClusterLayerClick}
        />
        <Layer
            {...incidentsClusterCountLayerConfig}
            id={INCIDENTS_LAYER_CLUSTER_COUNT}
        />
    </IncidentsSource>
}

IncidentsLayers.propTypes = Source.propTypes