import * as Sentry from "@sentry/react"
import { setUseWhatChange } from "@simbathesailor/use-what-changed"
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { RouterProvider } from "react-router-dom"

import "@l2r-front/l2r-i18n"
import { client } from "@l2r-front/l2r-query"
import { CssBaseline, GlobalStyles, globalStyles } from "@l2r-front/l2r-ui"
import { useUpdateWhenVisible } from "@l2r-front/l2r-utils"

import "./app.css"
import { router } from "./common/routers/AppRouter"

broadcastQueryClient({
    queryClient: client,
    broadcastChannel: "l2r-services",
})

const sentryDNS = process.env["NX_SENTRY_DSN"]
if (sentryDNS) {
    let environment = {}
    environment.name = process.env["NX_ENVIRONMENT"] || ""
    environment.type = environment.name

    // We don't want an env per specific sandbox
    //  sandbox NX_ENVIRONMENT looks like: sandbox-788e55063e754446jf19ee1fe511f0365c3e2c76
    if (environment.name.startsWith("sandbox-")) {
        environment.type = "sandbox"
    }

    Sentry.init({
        dsn: sentryDNS,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
            }),
        ],
        debug: false,
        tracesSampleRate: 1.0,
        environment: environment.type,
        release: process.env["NX_RELEASE_VERSION"],
        replaysSessionSampleRate: Number(process.env["NX_SENTRY_REPLAY_RATE"]) || 0,
        replaysOnErrorSampleRate: 1.0,
    })

    Sentry.setTag("environment_name", environment.name)
}

setUseWhatChange(process.env.NODE_ENV === "development")

window.addEventListener("unload", () => {
    if (window.opener) {
        window.opener.postMessage("childWindowClosed", process.env["NX_FRONT_L2R"])
    }
})


export function App() {

    useUpdateWhenVisible()

    return (
        <QueryClientProvider client={client}>
            <ReactQueryDevtools initialIsOpen={false} />
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <RouterProvider router={router} />
        </QueryClientProvider>
    )
}