import { useMatch, useRoutes } from "react-router-dom"

import { MapAdapterRoad, MapAdapterBoundingBox, MapAdapterFeature } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWORKS } from "../../../../common/constants/modules"
import { RoadworksDetailSegmentSelectListener } from "../../containers/RoadworksDetailSegmentSelectListener"
import { RoadworksSegmentSelectListener } from "../../containers/RoadWorksSegmentSelectListener/RoadworksSegmentSelectListener"
import { MobileRoadworksDetailPage } from "../../pages/MobileRoadworksDetailPage"
import { MobileworksPage } from "../../pages/MobileRoadworksPage"
import { MobileRoadworksSectionPage } from "../../pages/MobileRoadworksSectionPage"

export const RoadworksMobileRoutes = () => {

    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWORKS}/:road/:roadworkUuid`)

    const roadworksMobileRoutes = useRoutes([
        {
            path: "/",
            element:
                <>
                    <MapAdapterBoundingBox />
                    <RoadworksSegmentSelectListener />
                    <MobileworksPage />
                </>,
        },
        {
            path: "/:road",
            element:
                <>
                    <MapAdapterBoundingBox />
                    <MobileRoadworksDetailPage />
                    <RoadworksDetailSegmentSelectListener />
                    <MapAdapterRoad />
                </>,
        },
        {
            path: "/:road/:roadworkUuid/*",
            element: <>
                <MapAdapterFeature
                    featureId={match?.params?.roadworkUuid}
                />
                <MobileRoadworksSectionPage />
            </>,
        },
    ])

    return roadworksMobileRoutes
}