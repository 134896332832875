import { useMemo, useCallback } from "react"

import {
    IMAGE_COLLECTION_SOURCE,
    IMAGE_COLLECTION_LAYER,
    IMAGE_COLLECTION_BORDER_LAYER,
    useOrderingLayers,
    Layer,
} from "@l2r-front/l2r-map"

import { useImageCollectionDispatchContext } from "../../contexts/ImageCollectionContext"
import { getImageCollectionBorderConfig, getImageCollectionConfig } from "../../utils/getImageCollectionConfig"
import { ImageCollectionSource } from "../ImageCollectionSource"

export function ImageCollectionLayer(props) {

    const {
        road,
    } = props

    const { setImageFeatureIdClicked } = useImageCollectionDispatchContext()

    const imageCollectionLayerConfig = useMemo(() => {
        return getImageCollectionConfig()
    }, [])

    const imageCollectionLayerBorderConfig = useMemo(() => {
        return getImageCollectionBorderConfig()
    }, [])

    useOrderingLayers([IMAGE_COLLECTION_BORDER_LAYER, IMAGE_COLLECTION_LAYER])

    const handleClick = useCallback(async (event) => {
        const feature = event?.features?.[0]

        if (!feature) {
            return
        } else {
            setImageFeatureIdClicked(feature.properties.uuid)
        }
    }, [setImageFeatureIdClicked])

    return (
        <ImageCollectionSource
            id={IMAGE_COLLECTION_SOURCE}
            tolerance={.001}
            road={road}
        >
            <Layer
                id={IMAGE_COLLECTION_BORDER_LAYER}
                {...imageCollectionLayerBorderConfig}
                onClick={handleClick}
            />
            <Layer
                id={IMAGE_COLLECTION_LAYER}
                {...imageCollectionLayerConfig}
            />
        </ImageCollectionSource>
    )
}