import { styled } from "@mui/material"

import { Tabs as UiTabs, TabPanel as UiTabPanel } from "../.."

const TABS_HEIGHT = 48

export const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
})

export const Tabs = styled(UiTabs)(({ theme }) => ({
    width: "100%",
    height: TABS_HEIGHT,

    "& .MuiButtonBase-root": {
        minHeight: TABS_HEIGHT,
        minWidth: 0,
        textTransform: "capitalize",
        fontSize: theme.typography["Small accent"].size,
        fontWeight: theme.typography["Small accent"].fontWeight,
    },

    "& .MuiTab-root.Mui-selected": {
        color: theme.palette["cta-bg/cta-bg-primary"].main,
    },
}))

export const TabPanel = styled(UiTabPanel)({
    height: `calc(100% - ${TABS_HEIGHT}px)`,
})
