import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { RoadDetailCity } from "../RoadDetailCity"
import * as Styled from "./RoadDetailInfo.styled"

export const RoadDetailInfo = (props) => {
    const {
        className,
        lengthLabel,
        LengthComponent,
    } = props

    const { road } = useParams()
    const { t } = useTranslation(I18N_NAMESPACE)

    return (
        <div className={className} id="road-detail-info">
            <Styled.List >
                <Styled.ListElement>
                    <Typography variant="Small accent">{t(I18N_NAMESPACE, "containers.segmentDetailInfo.road")}&nbsp;</Typography>
                    <Typography id="road-detail-road" variant="Regular">{road}</Typography>
                </Styled.ListElement>
                <Styled.ListElement>
                    <Typography variant="Small accent">{t(I18N_NAMESPACE, "containers.segmentDetailInfo.city")}&nbsp;</Typography>
                    <RoadDetailCity road={road} />
                </Styled.ListElement>
                {LengthComponent && lengthLabel && <Styled.ListElement>
                    <Typography variant="Small accent">{lengthLabel}&nbsp;</Typography>
                    {LengthComponent}
                </Styled.ListElement>}
            </Styled.List>
        </div>
    )
}

RoadDetailInfo.defaultProps = {
    LengthComponent: null,
}

RoadDetailInfo.propTypes = {
    className: PropTypes.string,
    LengthComponent: PropTypes.element,
}