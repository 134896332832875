import {
    styled,
    Drawer as UiDrawer,
} from "@mui/material"

import { SIDEBAR_WIDTH } from "../../constants/layout"

export const Drawer = styled(UiDrawer)(({ theme }) => ({
    position: "relative",
    height: "100%",
    width: SIDEBAR_WIDTH,
    "& .MuiDrawer-paper": {
        position: "absolute",
        height: "100%",
        width: "100%",
        borderWidth: 0,
        padding: theme.spacing(5, 0, 5, 0),
    },
}))