import { useRoutes } from "react-router-dom"

import { MapAdapterRoad, MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { RoadwayDetailSegmentSelectListener } from "../../containers/RoadwayDetailSegmentSelectListener"
import { RoadwaySegmentSelectListener } from "../../containers/RoadwaySegmentSelectListener"
import { MobileRoadwayDetailPage } from "../../pages/MobileRoadwayDetailPage"
import { MobileRoadwayPage } from "../../pages/MobileRoadwayPage"

export const RoadwayMobileRoutes = () => {

    const roadwayRoutes = useRoutes([
        {
            path: "/*",
            element:
                <>
                    <MapAdapterBoundingBox />
                    <RoadwaySegmentSelectListener />
                    <MobileRoadwayPage />
                </>,
        },
        {
            path: "/:road/*",
            element:
                <>
                    <MapAdapterRoad />
                    <RoadwayDetailSegmentSelectListener />
                    <MobileRoadwayDetailPage />
                </>,
        },
    ])

    return roadwayRoutes
}