import { AxisLeft as VisxAxisLeft, AxisBottom as VisxAxisBottom } from "@visx/axis"
import { Tooltip as VisxTooltip } from "@visx/tooltip"

import { Skeleton as UiSkeleton, styled, Typography } from "@l2r-front/l2r-ui"
export const Wrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
})

export const Container = styled("div")({
    height: "100%",
    overflow: "hidden",
    width: "100%",
})

export const ChartContainer = styled("div")({
    flex: "1 1 auto",
    height: "100%",
    width: "100%",
})

export const AxisBottom = styled(VisxAxisBottom)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
}))

export const Axis = styled(VisxAxisLeft)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
}))

export const LegendsWrapper = styled("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(isMobile ? 2 : 5),
}))

export const LegendWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing(2),
}))

export const Color = styled("div")(({ color, theme }) => ({
    backgroundColor: color,
    height: 20,
    marginRight: theme.spacing(2),
    width: 20,
}))

export const Tooltip = styled(VisxTooltip)({
    display: "flex",
    flexDirection: "column",
})

export const LegendTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}))

export const AxisWrapper = styled("svg")({
    position: "absolute",
})

export const ZoomContainer = styled("div")({
    height: "100%",
    position: "relative",
    width: "100%",
})

export const Skeleton = styled(UiSkeleton)({
    height: "100%",
    transform: "scale(1)",
    width: "100%",
})