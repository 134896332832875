import { useL2rQuery } from "@l2r-front/l2r-query"

export function useAuthQuery(
    url,
    queryKey,
    queryParameters,
    config = {},
    initialData,
) {
    const apiUrl = `auth/${url}`

    return useL2rQuery(
        apiUrl,
        queryKey,
        queryParameters,
        config,
        initialData,
    )
}