import { Navigate, useRoutes } from "react-router-dom"

import { MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { MapAdapterIncident } from "../../containers/MapAdapterIncident"
import { IncidentDetailsPage } from "../../pages/IncidentDetailsPage"

export const IncidentsDesktopRoutes = () => {
    const incidentsRoutes = useRoutes([
        {
            path: "/:eventId/details",
            element:
                <>
                    <IncidentDetailsPage />
                    <MapAdapterIncident />
                </>,
        },
        {
            path: "/:eventId/*",
            element: <MapAdapterIncident />,
        },
        {
            path: "/filters",
            element: <Navigate replace to="../" />,
        },
        {
            path: "/*",
            element: <MapAdapterBoundingBox />,
        },
    ])

    return incidentsRoutes
}