import { useEffect } from "react"

import { useRoadwayDispatchContext } from "../../contexts/RoadwayContext"

export function RoadwaySegmentSelectListener() {

    const { setSelectedSegment } = useRoadwayDispatchContext()

    useEffect(() => {
        setSelectedSegment(null)
    }, [setSelectedSegment])
}