import { useMemo } from "react"

import { Navigate, Route, Routes, useLocation } from "react-router-dom"

import { AnonymousLayout } from "../layouts/AnonymousLayout/AnonymousLayout"
import { LoginPage } from "../pages/LoginPage"
import { RecoverPasswordDonePage } from "../pages/RecoverPasswordDonePage"
import { RecoverPasswordPage } from "../pages/RecoverPasswordPage"

export const AnonymousRouter = () => {
    const location = useLocation()

    const redirectURL = useMemo(() => {
        return encodeURI(`${location.pathname}${location.search}`)
    }, [location])

    return (
        <AnonymousLayout>
            <Routes>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/recover_password" element={<RecoverPasswordPage />} />
                <Route path="/recover_password/done" element={<RecoverPasswordDonePage />} />
                <Route path="*" element={<Navigate replace to={`/login?redirect=${redirectURL}`} />} />
            </Routes>
        </AnonymousLayout>
    )
}
