import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { Sidebar as L2RSidebar } from "@l2r-front/l2r-ui"

import { RoadworkSectionSidebar } from "../../containers/RoadworkSectionSidebar"
import { RoadworksRoadSidebar } from "../../containers/RoadworksRoadSidebar"
import { RoadworksSidebar } from "../../containers/RoadworksSidebar"

export function RoadworksSidebarRouter() {

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <RoadworksSidebar /> },
        { path: "/:road/*", element: <RoadworksRoadSidebar /> },
        { path: "/:road/:roadworkUuid/*", element: <RoadworkSectionSidebar /> },
    ]), [])

    return <L2RSidebar>{useRoutes(sidebarRoutes)}</L2RSidebar>
}
