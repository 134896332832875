import { useCallback, useEffect, useMemo } from "react"

import { useQueryClient } from "@tanstack/react-query"
import decode from "jwt-decode"
import { useNavigate } from "react-router-dom"
import { useLocalStorage, useTimeoutFn, useUpdateEffect } from "react-use"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useRefreshTokenMutation } from "../../hooks/mutations/useRefreshTokenMutation"
import { initialStateContext, AuthenticationStateContext, AuthenticationDispatchContext } from "./AuthenticationContext.context"

const REFRESH_TIMEDELTA = 1000 * 60 * 1 // 1 minute

export const AuthenticationContextProvider = (props) => {
    const {
        children,
    } = props

    const [localState, setLocalState] = useLocalStorage("authentication", initialStateContext)
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const login = useCallback((response) => {
        const { access, refresh } = response
        setLocalState({
            accessToken: access,
            refreshToken: refresh,
        })
    }, [setLocalState])

    const logout = useCallback(() => {
        setLocalState(initialStateContext)
        queryClient.clear()
        navigate("/")
    }, [setLocalState, queryClient, navigate])

    const {
        mutateAsync: refreshTokenMutation,
        isLoading: isRefreshing,
    } = useRefreshTokenMutation({ onError: logout })

    const refresh = useCallback(async () => {
        if (!isRefreshing) {
            const response = await refreshTokenMutation({ refresh: localState.refreshToken })
            const { access, refresh } = response
            setLocalState({
                accessToken: access,
                refreshToken: refresh,
            })
        }
    }, [isRefreshing, localState, refreshTokenMutation, setLocalState])

    const refreshTokenExpirationTimestamp = useMemo(() => {
        if (!localState.refreshToken) {
            return 0
        }
        const { exp: expirationTimestampInSeconds } = decode(localState.refreshToken)
        return expirationTimestampInSeconds * 1000
    }, [localState])

    const accessTokenExpirationTimestamp = useMemo(() => {
        if (!localState.accessToken) {
            return 0
        }
        const { exp: expirationTimestampInSeconds } = decode(localState.accessToken)
        return expirationTimestampInSeconds * 1000
    }, [localState])

    const refreshTokenTimeoutDelay = useMemo(() => {
        if (!localState.refreshToken) {
            return Infinity
        }

        const nowTimestamp = Date.now()
        return accessTokenExpirationTimestamp - nowTimestamp - REFRESH_TIMEDELTA
    }, [accessTokenExpirationTimestamp, localState])

    const isAuthenticated = useMemo(() => {
        return accessTokenExpirationTimestamp > Date.now()
    }, [accessTokenExpirationTimestamp])

    const [_, cancelRefreshTimeout] = useTimeoutFn(refresh, refreshTokenTimeoutDelay)

    useUpdateEffect(() => {
        const nowTimestamp = Date.now()

        if (!localState.refreshToken || !localState.accessToken) {
            return
        }

        if (refreshTokenExpirationTimestamp < nowTimestamp) {
            logout()
            return
        }

        if (!isAuthenticated) {
            refresh()
            return
        }
    })

    useEffect(() => {
        if (!localState.refreshToken) {
            cancelRefreshTimeout()
        }
    }, [cancelRefreshTimeout, localState])

    const state = useMemo(() => {
        return ({
            ...localState,
            isAuthenticated,
            isRefreshing,
        })
    }, [localState, isAuthenticated, isRefreshing])

    const dispatchValue = useMemo(() => {
        return {
            login,
            logout,
        }
    }, [login, logout])

    return <AuthenticationStateContext.Provider value={state} >
        <AuthenticationDispatchContext.Provider value={dispatchValue}>
            {children}
        </AuthenticationDispatchContext.Provider>
    </AuthenticationStateContext.Provider>
}

AuthenticationContextProvider.propTypes = {
    children: PropTypes.node,
}