import { useMemo } from "react"

import { useMatch, useRoutes } from "react-router-dom"

import { MapAdapterRoad, MapAdapterBoundingBox, MapAdapterFeature } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWORKS } from "../../../../common/constants/modules"
import { RoadworksDetailSegmentSelectListener } from "../../containers/RoadworksDetailSegmentSelectListener"
import { RoadworksSegmentSelectListener } from "../../containers/RoadWorksSegmentSelectListener"

export function RoadworksDesktopRoutes() {

    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWORKS}/:road/:roadworkUuid`)

    const roadworksRoutes = useMemo(() => ([
        {
            path: "/",
            element: <>
                <MapAdapterBoundingBox />
                <RoadworksSegmentSelectListener />
            </>,
        },
        {
            path: "/:road/*",
            element: <>
                <MapAdapterRoad />
                <RoadworksDetailSegmentSelectListener />
            </>,
        },
        {
            path: "/:road/:roadworkUuid/*",
            element: <>
                <MapAdapterFeature featureId={match?.params?.roadworkUuid} />
                <RoadworksDetailSegmentSelectListener />
            </>,
        },
    ]), [match])

    return useRoutes(roadworksRoutes)
}