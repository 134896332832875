import { useLayoutEffect, useReducer } from "react"

export function useUpdateWhenVisible() {
    const [_, forceUpdate] = useReducer(x => x + 1, 0)

    useLayoutEffect(() => {
        document.addEventListener("visibilitychange", forceUpdate)

        return () => document.removeEventListener("visibilitychange", forceUpdate)
    })

    return
}